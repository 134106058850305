// Import font packs here.
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AppFooter, Card, CenteredArea, ContentArea, Copyright, GoToTopButton, LeftContent, PublicContent, RightContent, Title, TopNavigation, TopNavLeftIcon, TopNavLeftTitle, TopNavRightContent,} from "./BaseStyle";
import InstructorsList from "./components/InstructorsList";
import Quote from "./components/QuoteBlock";
import DojoList from "./components/DojoList";
import {useRef} from "react";
import Hero from "./components/Hero";

library.add(fas);

function App() {
    const homeRef = useRef(null);

    const executeScroll = () => {
        console.log(homeRef.current.offsetTop);
        document.getElementById("homeRef").scrollTo({
            behavior: 'smooth',
            top: homeRef.current.offsetTop
        })
    };

    //current..scrollIntoView()

    const Quotes = [
        {
            quote: "Karate is like boiling water: without heat, it returns to its tepid state.",
            author: "Gichin Funakoshi"
        },
        {
            quote: "The mystery existing between an open moment and a person's preparedness reveals the truth.",
            author: "Masatoshi Nakayama"
        },
        {
            quote: "Karate practitioners and other martial artists must know that the martial arts are about more than physical development, self-defense, and competition; most importantly, they are about continually striving to perfect one’s character.",
            author: "Teruyuki Okazaki"
        },
    ]


    return (
        <div className="App">
            <PublicContent ref={homeRef} id={"homeRef"}>
                <TopNavigation>
                    <LeftContent onClick={() => {
                        executeScroll();
                    }}>
                        <TopNavLeftIcon><img height={"60px"} src={"data/iskf-logo-reverse.png"} alt={"logo"}/></TopNavLeftIcon>
                        <TopNavLeftTitle>ISKF Alberta </TopNavLeftTitle>
                    </LeftContent>

                    <RightContent>

                    </RightContent>
                </TopNavigation>
                <ContentArea>
                    <Hero/>

                    <Quote QuoteText={Quotes[0].quote} QuoteTextAuthor={Quotes[0].author}/>

                    <InstructorsList/>
                    <Quote QuoteText={Quotes[1].quote} QuoteTextAuthor={Quotes[1].author}/>

                    <DojoList/>
                    <Quote QuoteText={Quotes[2].quote} QuoteTextAuthor={Quotes[2].author}/>

                    <CenteredArea>
                        <Card width={"900px"} height={"100px"}>
                            <Title>About ISKF</Title>
                            The ISKF was founded in September 1977.
                            Today, the ISKF is comprised of over twenty-eight Pan American countries, and over
                            thirty-two countries from Europe, Asia, the Middle East, and Africa.
                            The ISKF is still growing and continually adds new clubs and countries to its membership,
                            and currently has over 75,000 individual members worldwide.
                        </Card>
                    </CenteredArea>

                </ContentArea>
                <AppFooter>
                    <Copyright>&copy; Copyright 2021 - ISKF Alberta </Copyright>
                    <Copyright>Hero Photo by:  Raquel Pedrotti on Unsplash </Copyright>
                </AppFooter>
            </PublicContent>
            <GoToTopButton onClick={() => {
                executeScroll();
            }}>
                <FontAwesomeIcon icon={"chevron-up"}/>
            </GoToTopButton>
        </div>
    );
}

export default App;
