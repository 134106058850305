import React from "react";
import {Card, ImageCard} from "../BaseStyle";
import styled from "styled-components";

const InstructorTitle = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
`;

const InstructorDetail = styled.div`
  font-size: 1.0em;
  padding-top: 10px;
`;

const InstructorCard = ({instructor}) => {

    const InstructorLevel = ({instructor}) => {
        return instructor.instructorLevel ?
            <InstructorDetail><strong>ISKF Instructor:</strong> {instructor.instructorLevel}</InstructorDetail> : <></>
    }

    const ExaminerLevel = ({instructor}) => {
        return instructor.examinerLevel ?
            <InstructorDetail><strong>ISKF Examiner:</strong> {instructor.examinerLevel}</InstructorDetail> : <></>
    }
    const JudgeLevel = ({instructor}) => {
        return instructor.judgeLevel ?
            <InstructorDetail><strong>ISKF Judge:</strong> {instructor.judgeLevel}</InstructorDetail> : <></>
    }

    const Qualifications = ({instructor}) => {
        return (
            <>
                <InstructorLevel instructor={instructor}/>
                <ExaminerLevel instructor={instructor}/>
                <JudgeLevel instructor={instructor}/>
            </>
        )
    }

    return (
        <>
            <Card height={'580px'} width={'300px'}>
                <ImageCard width={'300px'} height={'300px'}>
                    <img className="img-fluid" width={"300px"} height={"300px"}
                         src={`${process.env.PUBLIC_URL}/data/${instructor.imageRef}`}
                         alt="logo"/>
                </ImageCard>
                <InstructorTitle>{instructor.name} - {instructor.rank}</InstructorTitle>
                <InstructorDetail><strong>Dojo: </strong>{instructor.dojo}</InstructorDetail>
                <InstructorDetail><strong>City: </strong>{instructor.city}</InstructorDetail>
                <InstructorDetail><strong>Training Since: </strong>{instructor.trainingTime}</InstructorDetail>
                {instructor.graduateInstructor? <InstructorDetail>{instructor.graduateInstructor}</InstructorDetail>:<></>}
                <Qualifications instructor={instructor}/>
                {instructor.notes ? <InstructorDetail>{instructor.notes}</InstructorDetail> : <></>}
            </Card>
        </>)
}

export default InstructorCard;