import React from "react";
import {CardAreaFixed, CenteredArea, Title} from "../BaseStyle";
import DojoCard from "./DojoCard";


const DojoList = () => {
    const dojos = [
        {
            name: "ISKF Calgary",
            chiefInstructor: "David Jones",
            address: "2, 3515 32nd Street NE. Calgary, AB. T1Y 5Y9",
            phone: "403-250-3230",
            email: "info@iskfcalgary.ca",
            url: "https://www.iskfcalgary.ca",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.290068477331!2d-113.9913114835877!3d51.084654079567756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537164ea3043735f%3A0xa1fef210617d9192!2sISKF+Calgary!5e0!3m2!1sen!2sca!4v1555269663832!5m2!1sen!2sca"
        },
        {
            name: "St.Albert Karate Club",
            chiefInstructor: "Vic Charlton",
            address: "St. Albert Community Hall, 17 Perron Street. St Albert, AB T8N 1E5",
            phone: "780-984-4130",
            email: "vcharlt1@telus.net",
            // url: "https://www.intrinsicphonics.net/Teaching_Reading/About_the_SAKC/About_the_SAKC.html",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2365.702826493081!2d-113.6276637240052!3d53.6344392583565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0267621ffb497%3A0xdc23ee452f51d89!2sSt.+Albert+Community+Hall!5e0!3m2!1sen!2sca!4v1555270289614!5m2!1sen!2sca"
        },
        {
            name: "ISKF Shotokan Karate Club of Red Deer",
            chiefInstructor: "Cheri Vincent",
            address: "E-6 5560-45 Street Red Deer, AB. T4N 1L1",
            phone: "403-347-0646",
            email: "info@reddeerkarate.com",
            url: "http://www.reddeerkarate.com/",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9766.073588695752!2d-113.84286099628052!3d52.2702900262823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa4cb52dbce2457a5!2sShotokan+Karate+Club+of+Red+Deer!5e0!3m2!1sen!2sca!4v1555270403808!5m2!1sen!2sca"
        },
        {
            name: "Shotokan Karate Club – Calgary NW (SKC Calgary NW)",
            chiefInstructor: "Phillip Baker",
            // address: "205, 8579 Scurfield Drive NW, Calgary, AB. T3L 1Z6",
            phone: "403-375-1441",
            email: "info@shotokankaratecalgary.com",
            url: "https://www.shotokankaratecalgary.com/",
            mapUrl: ""
        },
        {
            name: "Three Hills Karate Club",
            chiefInstructor: "Dawn Kinsey ",
            address: "Three Hills Centennial Arena, 202 3rd Ave. N. Three Hills, AB. T0M 2A0",
            phone: "403-443-1193",
            email: "threehillskarate@gmail.com",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.485823574827!2d-113.2708668827714!3d51.70585378365377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5373804da6972557%3A0x9e4c7b17ac19b34!2sCentennial+Place+Arena!5e0!3m2!1sen!2sca!4v1555624031829!5m2!1sen!2sca"
        },
    ]

    return (
        <>
            <CenteredArea>
                <Title>Our Clubs are located around the province</Title>
                <p>With Karate clubs across the province of Alberta, there’s a Dojo close by.
                    If you’ve never trained before, or visiting the province,
                    we would love to see you. </p>
            </CenteredArea>
            <CenteredArea>
                <CardAreaFixed>
                    {dojos.map((dojo, index)=>(
                        <DojoCard dojo={dojo} orientation={index} key={dojo.name}/>
                    ))}
                </CardAreaFixed>
            </CenteredArea>
        </>
    );
}

export default DojoList;