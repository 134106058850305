import React from "react";
import {CardAreaFixed, CenteredArea, Title} from "../BaseStyle";
import InstructorCard from "./InstructorCard";

const InstructorsList = () => {

    const instructors = [
        {
            name: "David Jones",
            rank: "8th Dan",
            dojo: "ISKF Calgary",
            city: "Calgary",
            trainingTime: "1973",
            graduateInstructor: "ISKF Graduate Instructor",
            instructorLevel: "Class A",
            examinerLevel: "Class A",
            judgeLevel: "Class A",
            notes: "Member of the ISKF Technical Committee",
            imageRef: "David.png",
        },
        {
            name: "Laurie Jones",
            rank: "7th Dan",
            dojo: "ISKF Calgary",
            city: "Calgary",
            trainingTime: "1979",
            judgeLevel: "Class A",
            imageRef: "Laurie.png",
        },
        {
            name: "Robert Morgan",
            rank: "6th Dan",
            dojo: "ISKF Calgary",
            city: "Calgary",
            trainingTime: "1988",
            graduateInstructor: "ISKF Graduate Instructor",
            instructorLevel: "Class C",
            examinerLevel: "Class D",
            judgeLevel: "Class B",
            imageRef: "Robert.png",
        },
        {
            name: "Phillip Baker",
            rank: "6th Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "1981",
            judgeLevel: "Class B",
            imageRef: "Phillip.jpg",
        },
        {
            name: "Crystal Shatz",
            rank: "5th Dan",
            dojo: "ISKF Calgary",
            city: "Calgary",
            trainingTime: "1995",
            instructorLevel: "Trainee",
            judgeLevel: "Class B",
            imageRef: "Crystal.png",
        },
        {
            name: "Nancy Berreth",
            rank: "4th Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "1992",
            imageRef: "Nancy.jpg",
        },
        {
            name: "Cheri Vincent",
            rank: "3rd Dan",
            dojo: "ISKF Shotokan Karate Club of Red Deer",
            city: "Red Deer",
            trainingTime: "1992",
            judgeLevel: "Class C",
            imageRef: "CheriV.jpg",
        },
        {
            name: "Steve Brown",
            rank: "3rd Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "2005",
            judgeLevel: "Class C",
            imageRef: "Steve.jpg",
        },
        {
            name: "Pat McGrath",
            rank: "3rd Dan",
            dojo: "ISKF Shotokan Karate Club of Red Deer",
            city: "Red Deer",
            trainingTime: "1996",
            judgeLevel: "Class D",
            imageRef: "PatM.jpg",
        },
        {
            name: "Vic Charlton",
            rank: "3rd Dan",
            dojo: "St. Albert Karate Club",
            city: "St. Albert",
            trainingTime: "1965",
            imageRef: "VicC.png",
        },
        {
            name: "Dawn Kinsey",
            rank: "3rd Dan",
            dojo: "Three Hills Karate Club",
            city: "Three Hills",
            trainingTime: "1997",
            imageRef: "DawnK.jpg",
        },
        {
            name: "Mike Tremblay",
            rank: "2nd Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "2002",
            imageRef: "Mike.jpg",
        },
        {
            name: "Natasha Landra",
            rank: "2nd Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "1997",
            imageRef: "Natasha.jpg",
        },
        {
            name: "Radu Tecuceanu",
            rank: "1st Dan",
            dojo: "Shotokan Karate Club Calgary NW",
            city: "Calgary",
            trainingTime: "1992",
            imageRef: "Radu.jpg",
        },
    ]

    return (
        <>
            <CenteredArea>
                <Title>Our provincial Instructors</Title>
                <p>Throughout the province, we are very fortunate to have a number of experienced and dedicated
                    instructors teaching Shotokan Karate to students of all ages.
                </p>
            </CenteredArea>
            <CenteredArea>
                <CardAreaFixed>
                    {instructors.map(instructor => (
                        <InstructorCard instructor={instructor} key={instructor.name}/>
                    ))}
                </CardAreaFixed>
            </CenteredArea>
        </>
    );
}

export default InstructorsList;