import React from "react";
import {Parallax} from 'react-parallax';
import styled from "styled-components";
import {theme} from "../BaseStyle";

const HeroTitleContainer = styled.div`
  position: relative;
  height: 700px;
  top: 200px;
  color: ${theme.background};
  padding-left: 50px;
  @media screen and (max-width: 800px) {
    padding-left: 10px;
`
const HeroTitle = styled.div`
  font-size: 2.8em;
  @media screen and (max-width: 800px) {
    font-size: 2em;
  }
`

const HeroSubTitle = styled.div`
  font-size: 2.0em;
  padding-top: 15px;

  @media screen and (max-width: 800px) {
    font-size: 1.2em;
  }
`


const Hero = () => {

    return (
        <>
            <Parallax
                // blur={{ min: -15, max: 15 }}
                bgImage="raquel-pedrotti-552824-unsplash.jpg" bgImageAlt="the cat"
                strength={-200}
                bgImageSize={{height: '400px'}}
            >

                <HeroTitleContainer>
                    <HeroTitle>
                        Welcome to ISKF Alberta.
                    </HeroTitle>
                    <HeroSubTitle>
                        The home of the International Shotokan Karate Federation in Alberta.
                    </HeroSubTitle>

                </HeroTitleContainer>

            </Parallax>
        </>
    )

}

export default Hero;
