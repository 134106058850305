import React from "react";
import {QuoteBlock, QuoteText, QuoteTextAuthor} from "../BaseStyle";

const Quote = (props) => {
    return (
        <>
            <QuoteBlock>
                <QuoteText>{props.QuoteText}</QuoteText>
                <QuoteTextAuthor>- {props.QuoteTextAuthor}</QuoteTextAuthor>
            </QuoteBlock>
        </>
    );
}

export default Quote;