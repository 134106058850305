import React from "react";
import {Card, DojoContainer, DojoEntryBlock, Title} from "../BaseStyle";
import styled from "styled-components";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const ContactCard = styled.div`
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
`;

const ContactLine = styled.div`
  font-size: 1.8em;
  padding-bottom: 25px;
  word-wrap: break-word;

  @media screen and (max-width: 800px) {
    font-size: 1.2em;
    text-align: center;
  }
`;

const MapBlock = styled(DojoEntryBlock)`
  @media screen and (max-width: 800px) {
    display: none;
  }
`
const DojoCardBlock = styled(Card)`
  @media screen and (max-width: 800px) {
    height: 380px;
    font-size: 0.8em;
  }
`;

const DojoTitle = styled(Title)`
  font-size: 1.4em;
  @media screen and (max-width: 800px) {
    font-size: 1.1em;
  }
`

const DojoCard = ({dojo, orientation}) => {
    // const rightMap = orientation % 2;
    const float = (orientation % 2) ? 'right' : 'left'

    const ContactRow = ({icon, text}) => {
        if (text) {
            return (
                <>
                    <ContactLine><FontAwesomeIcon icon={icon}/> {text}</ContactLine>
                </>
            );
        }
        return (<></>);
    }
const mapUrl = dojo.mapUrl;
    console.log(mapUrl);

    return (
        <>
            <DojoCardBlock height={"480px"} width={"100%"}>
                <DojoContainer>
                    <MapBlock floatLeft={float}>
                        <iframe title={dojo.url} width="100%" height="100%" frameBorder="0px" src={mapUrl}/>
                    </MapBlock>
                    <DojoEntryBlock>
                        <ContactCard>
                            <ContactLine><DojoTitle>{dojo.name}</DojoTitle></ContactLine>
                            <ContactRow icon={"user"} text={dojo.chiefInstructor}/>
                            <ContactRow icon={"map-marked"} text={dojo.address}/>
                            <ContactRow icon={"phone-alt"} text={dojo.phone}/>
                            <ContactRow icon={"paper-plane"} text={dojo.email}/>
                            <ContactRow icon={"globe"} text={dojo.url}/>
                        </ContactCard>
                    </DojoEntryBlock>
                </DojoContainer>
            </DojoCardBlock>
        </>
    )
}

export default DojoCard;