import styled from "styled-components";


const TopNavHeight = '80px';
const FooterHeight = '30px';

const CardRadius = '15px';

const baseColors = {
    blue: '#053293',
    white: '#ffffff',
    yellow: '#ffec40',
    green: '#046821',
    grey: '#bebebe',
    gold: '#fdbb31',
    headerBlue: '#043293',
    darkBlue: '#18355e',
}

export const theme = {
    textColor: baseColors.headerBlue,
    background: baseColors.white,
    quoteColor: baseColors.gold,
    quoteBackground: baseColors.darkBlue,
    boldColor: baseColors.darkBlue,
    bodyHeaderColor: baseColors.headerBlue,
    topNavBackground: baseColors.darkBlue,
    topNavColor: baseColors.gold,
    topNavHoverColor: baseColors.yellow,
    footerBackground: baseColors.darkBlue,
    footerColor: baseColors.gold
}
// Wraps the Content area only.
export const PublicContent = styled.div`
  position: fixed;
  top: ${TopNavHeight};
  left: 0;
  right: 0;
  min-height: calc(100% - ${TopNavHeight} - ${FooterHeight});
  overflow: auto;
`;

export const TopNavigation = styled.div`
  position: fixed;
  background: ${theme.topNavBackground};
  color: ${theme.gold};
  width: 100%;
  top: 0;
  right: 0;
  height: ${TopNavHeight};
  z-index: 1;
`;

export const TopNavRightContent = styled.div`
  margin-top: calc(${TopNavHeight} * 0.3);;
  padding-right: 10px;
  font-size: 1em;
`;

export const RightContent = styled.div`
  position: relative;
  float: right;
`;

export const LeftContent = styled.div`
  position: relative;
  float: left;
`;

// Main body of content
export const ContentArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //width: calc(100% - 20px);
  width: 100%;
  background: ${theme.background};
  color: ${theme.textColor};
  //padding: 10px;
  font-size: 1em;
`;

export const CenteredArea = styled.div.attrs(props => ({
    justify: props.justify,
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.justify ? props.justify : 'center'};
`;

export const TopNavLeftIcon = styled.span`
  color: ${theme.topNavColor};
  font-size: 1em;
    //border: ${theme.topNavColor} solid 1px;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  margin-left: 5px;

  &:hover {
    color: ${theme.topNavHoverColor};
    cursor: pointer;
  }
`;

export const TopNavLeftTitle = styled.span`
  color: ${theme.topNavColor};
  font-size: 1.5em;
    //border: ${theme.topNavColor} solid 1px;
  border-radius: 2px;
  text-align: left;
  vertical-align: middle;
  //width: 60px;
  //height: 60px;

  margin-left: 5px;
  left: 80px;

  &:hover {
      //color: ${theme.topNavHoverColor};
    cursor: pointer;
    //text-decoration: underline;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.9em;
  }
`;

export const Link = styled.span.attrs(props => ({
    hoverColor: props.hoverColor,
}))`
  //font-size: 0.8em;
  color: ${props => props.color ? props.color : theme.topNavColor};

  &:hover {
    color: ${props => props.hoverColor ? props.hoverColor : theme.topNavHoverColor};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const LinkButton = styled(Link)`
  padding-right: 2px;
  padding-left: 2px;
`;

//
// export const Hero = styled.div`
//   padding: 10px;
// `;

export const QuoteBlock = styled.div`
  background: ${theme.quoteBackground};
  color: ${theme.quoteColor};
  width: 100%;
  left: 0;
  right: 0;

  font-size: 1.5em;
  text-align: center;

  padding-top: 20px;
  padding-bottom: 15px;
`;
export const QuoteText = styled.div`
  margin: 15px 50px 10px;
`;

export const QuoteTextAuthor = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-style: italic;
`;

export const CardAreaFixed = styled.div.attrs(props => ({
    areaWidth: props.areaWidth,
}))`
  width: ${props => props.areaWidth || '80%'};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.justify ? props.justify : 'center'};
`;

export const Card = styled.div.attrs(props => ({
    borderColor: props.borderColor,
    width: props.width,
    height: props.height,
}))`
  float: left;
  width: ${props => props.width || `350px`};
  height: ${props => props.height || `300px`};
  margin: 15px;
  //margin-left: auto;
  //margin-right: auto;
  padding: 12px;
  border: solid 1px ${props => props.borderColor ? props.borderColor : 'transparent'};
  border-radius: ${CardRadius};
`;

export const ImageCard = styled.div.attrs(props => ({
    width: props.width,
    height: props.height,
}))`
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: ${props => props.width || `350px`};
  height: ${props => props.height || `350px`};
`;

export const Title = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  color: ${theme.boldColor};
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

export const DojoContainer = styled.div`
  height: 480px;
  margin: auto;
  padding: 2px;
`;

export const DojoEntryBlock = styled.div.attrs(props => ({
    floatLeft: props.floatLeft
}))`
  width: 49%;
  height: 480px;
  padding: 2px;
  float: ${props => props.floatLeft || 'left'};

  @media screen and (max-width: 800px) {
    font-size: 1.2em;
    width: 99%;
  }
`

export const Copyright = styled.span`
  font-size: 0.8em;
  color: ${theme.topNavColor};
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 800px) {
    font-size: 0.2em;
    padding-left: 2px;
    padding-right: 1px;
  }
`;

export const AppFooter = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: ${FooterHeight};
  background: ${theme.footerBackground};
  color: ${theme.footerColor};
  width: 100%;
  z-index: 1;
  font-size: 1.2em;
  text-align: center;
`;


export const GoToTopButton = styled.div`
  position: fixed;
  bottom: 35px;
  right: 35px;
  width: 33px;
  height: 33px;
  background: ${baseColors.grey};
  font-size: 25px;
  text-align: center;

  opacity: 30%;
  color: ${baseColors.green};
  border-radius: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;


  &:hover {
    cursor: pointer;
    opacity: 100%;
  }
`;
